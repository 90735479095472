const agreementErrorCode = {
  AGREEMENT_TENANT_NOT_ADDED: 'AGREEMENT_TENANT_NOT_ADDED',
  AGREEMENT_LANDLORD_NOT_ADDED: 'AGREEMENT_LANDLORD_NOT_ADDED',
  AGREEMENT_CREATOR_NOT_ADDED: 'AGREEMENT_CREATOR_NOT_ADDED',
  SECURITY_NOT_ACCEPTED_TENANT: 'SECURITY_NOT_ACCEPTED_TENANT',
  SECURITY_NOT_ACCEPTED_LANDLORD: 'SECURITY_NOT_ACCEPTED_LANDLORD',
  LANDLORD_BUSINESS_PROFILE_NOT_ALLOWED: 'LANDLORD_BUSINESS_PROFILE_NOT_ALLOWED',
  PERSONAL_LANDLORD_PROFILE_NOT_ALLOWED: 'PERSONAL_LANDLORD_PROFILE_NOT_ALLOWED',
  INCOMPLETE_LANDLORD_PROFILE_BUSINESS_ADDRESS:
    'INCOMPLETE_LANDLORD_PROFILE_BUSINESS_ADDRESS',
  INCOMPLETE_LANDLORD_PROFILE_BUSINESS_INFORMATION:
    'INCOMPLETE_LANDLORD_PROFILE_BUSINESS_INFORMATION',
  TENANT_BUSINESS_PROFILE_NOT_ALLOWED: 'TENANT_BUSINESS_PROFILE_NOT_ALLOWED',
  INCOMPLETE_LANDLORD_PROFILE_BUSINESS_COMPANY_NUMBER:
    'INCOMPLETE_LANDLORD_PROFILE_BUSINESS_COMPANY_NUMBER',
  INCOMPLETE_TENANT_PROFILE: 'INCOMPLETE_TENANT_PROFILE',
  INCORRECT_PERSONAL_ID_CODE_TENANT_PROFILE:
    'INCORRECT_PERSONAL_ID_CODE_TENANT_PROFILE',
  TENANT_IS_YOUNGER_THAN_18: 'TENANT_IS_YOUNGER_THAN_18',
  INCOMPLETE_LANDLORD_PROFILE: 'INCOMPLETE_LANDLORD_PROFILE',
  INCORRECT_PERSONAL_ID_CODE_LANDLORD_PROFILE:
    'INCORRECT_PERSONAL_ID_CODE_LANDLORD_PROFILE',
  LANDLORD_IS_YOUNGER_THAN_18: 'LANDLORD_IS_YOUNGER_THAN_18',
  TENANT_AND_LANDLORD_ARE_THE_SAME_PERSON: 'TENANT_AND_LANDLORD_ARE_THE_SAME_PERSON',
  SECURITY_NOT_ACCEPTED_CO_TENANT: 'SECURITY_NOT_ACCEPTED_CO_TENANT',
  INCORRECT_PERSONAL_ID_CODE_CO_TENANT_PROFILE:
    'INCORRECT_PERSONAL_ID_CODE_CO_TENANT_PROFILE',

  START_DATE_IN_PAST: 'START_DATE_IN_PAST',
  HANDOVER_DATE_IN_PAST: 'HANDOVER_DATE_IN_PAST',
  END_DATE_BEFORE_START_DATE: 'END_DATE_BEFORE_START_DATE',
  AGREEMENT_DURATION: 'AGREEMENT_DURATION',
  AGREEMENT_TYPE_IS_NOT_SPECIFIED: 'AGREEMENT_TYPE_IS_NOT_SPECIFIED',

  REQUIRED_FIELD: 'REQUIRED_FIELD',
  MUST_BE_A_POSITIVE_NUMBER: 'MUST_BE_A_POSITIVE_NUMBER',
  MUST_BE_AT_LEAST_LIMIT_CHARACTERS_LONG: 'MUST_BE_AT_LEAST_LIMIT_CHARACTERS_LONG',
  NUMBER_LESS_THAN_ALLOWED: 'NUMBER_LESS_THAN_ALLOWED',
  CONTAINS_AN_INVALID_VALUE: 'CONTAINS_AN_INVALID_VALUE',
  MUST_BE_AN_INTEGER: 'MUST_BE_AN_INTEGER',
};
const agreementErrorType = {
  tenantProfile: 'tenantProfile',
  landlordProfile: 'landlordProfile',
  creatorProfile: 'creatorProfile',
  TENANT_AND_LANDLORD_ARE_THE_SAME_PERSON: 'TENANT_AND_LANDLORD_ARE_THE_SAME_PERSON',
  coTenantProfile: 'coTenantProfile',

  START_DATE_IN_PAST: 'START_DATE_IN_PAST',
  HANDOVER_DATE_IN_PAST: 'HANDOVER_DATE_IN_PAST',
  END_DATE_BEFORE_START_DATE: 'END_DATE_BEFORE_START_DATE',
  AGREEMENT_DURATION: 'AGREEMENT_DURATION',
  AGREEMENT_TYPE_IS_NOT_SPECIFIED: 'AGREEMENT_TYPE_IS_NOT_SPECIFIED',

  addressFirstLine: 'addressFirstLine',
  addressHouseNumber: 'addressHouseNumber',
  addressStreet: 'addressStreet',
  addressCity: 'addressCity',
  addressCountry: 'addressCountry',
  addressIndex: 'addressIndex',
  startDate: 'startDate',
  endDate: 'endDate',
  handoverDate: 'handoverDate',
  rentAmount: 'rentAmount',
  bankAccountNumber: 'bankAccountNumber',
  bankAccountOwnerName: 'bankAccountOwnerName',
  paymentDay: 'paymentDay',
  insuranceInvoiceTo: 'insuranceInvoiceTo',
  objectArea: 'objectArea',
  numberOfRooms: 'numberOfRooms',
  additionalFees: 'additionalFees',
  servicesAndUtilities: 'servicesAndUtilities',
  administrativeFees: 'administrativeFees',
};

export function agreementValidationErrorTranslation(error) {
  const nuxtApp = useNuxtApp();
  const i18n = nuxtApp.$i18n;
  const { t } = i18n;

  // if error is missing
  if (!error) return;
  // Parties validation
  else if (
    error.message === agreementErrorCode.AGREEMENT_TENANT_NOT_ADDED &&
    error.type === agreementErrorType.tenantProfile
  )
    return t('agreement.error.missing_tenant');
  else if (
    error.message === agreementErrorCode.AGREEMENT_LANDLORD_NOT_ADDED &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('agreement.error.missing_landlord');
  else if (
    error.message === agreementErrorCode.AGREEMENT_CREATOR_NOT_ADDED &&
    error.type === agreementErrorType.creatorProfile
  )
    return t('profile.validation.creator_not_added');
  else if (
    error.message === agreementErrorCode.SECURITY_NOT_ACCEPTED_TENANT &&
    error.type === agreementErrorType.tenantProfile
  )
    return t('profile.validation.security_not_accepted_profile_tenant');
  else if (
    error.message === agreementErrorCode.SECURITY_NOT_ACCEPTED_LANDLORD &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('profile.validation.security_not_accepted_profile_landlord');
  else if (
    error.message === agreementErrorCode.LANDLORD_BUSINESS_PROFILE_NOT_ALLOWED &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('profile.validation.business_profile_landlord_not_allowed');
  else if (
    error.message === agreementErrorCode.PERSONAL_LANDLORD_PROFILE_NOT_ALLOWED &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('profile.validation.private_landlord_not_allowed');
  else if (
    error.message === agreementErrorCode.INCOMPLETE_LANDLORD_PROFILE_BUSINESS_ADDRESS &&
    error.type === agreementErrorType.landlordProfile
  )
    return t(
      'agreement.error.incomplete_profile_landlord_missing_address_creator_is_landlord',
    );
  else if (
    error.message ===
      agreementErrorCode.INCOMPLETE_LANDLORD_PROFILE_BUSINESS_INFORMATION &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('agreement.error.incomplete_landlord_profile_business_information');
  else if (
    error.message ===
      agreementErrorCode.INCOMPLETE_LANDLORD_PROFILE_BUSINESS_COMPANY_NUMBER &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('agreement.error.incomplete_landlord_profile_business_company_number');
  else if (
    error.message === agreementErrorCode.TENANT_BUSINESS_PROFILE_NOT_ALLOWED &&
    error.type === agreementErrorType.tenantProfile
  )
    return t('agreement.error.business_profile_tenant_not_allowed');
  else if (
    error.message === agreementErrorCode.INCOMPLETE_TENANT_PROFILE &&
    error.type === agreementErrorType.tenantProfile
  )
    return t('agreement.error.incomplete_profile_tenant');
  else if (
    error.message === agreementErrorCode.INCORRECT_PERSONAL_ID_CODE_TENANT_PROFILE &&
    error.type === agreementErrorType.tenantProfile
  )
    return t('agreement.error.incorrect_personal_id_code_tenant_profile');
  else if (
    error.message === agreementErrorCode.TENANT_IS_YOUNGER_THAN_18 &&
    error.type === agreementErrorType.tenantProfile
  )
    return t('agreement.error.tenant_is_younger_than_18');
  else if (
    error.message === agreementErrorCode.INCOMPLETE_LANDLORD_PROFILE &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('agreement.error.incomplete_landlord_profile');
  else if (
    error.message === agreementErrorCode.INCORRECT_PERSONAL_ID_CODE_LANDLORD_PROFILE &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('agreement.error.incorrect_personal_id_code_landlord_profile');
  else if (
    error.message === agreementErrorCode.LANDLORD_IS_YOUNGER_THAN_18 &&
    error.type === agreementErrorType.landlordProfile
  )
    return t('agreement.error.landlord_is_younger_than_18');
  else if (
    error.message === agreementErrorCode.TENANT_AND_LANDLORD_ARE_THE_SAME_PERSON &&
    error.type === agreementErrorType.TENANT_AND_LANDLORD_ARE_THE_SAME_PERSON
  )
    return t('agreement.error.tenant_and_landlord_are_the_same_person'); // TODO Check error.type
  else if (
    error.message === agreementErrorCode.SECURITY_NOT_ACCEPTED_CO_TENANT &&
    error.type === agreementErrorType.coTenantProfile
  )
    return t('agreement.error.security_not_accepted_co_tenant');
  else if (
    error.message === agreementErrorCode.INCORRECT_PERSONAL_ID_CODE_CO_TENANT_PROFILE &&
    error.type === agreementErrorType.coTenantProfile
  )
    return t('"profile.validation.id_code_incorrect_cotenant');
  // Business logic validation
  else if (
    error.message === agreementErrorCode.START_DATE_IN_PAST &&
    error.type === agreementErrorType.START_DATE_IN_PAST
  )
    return t('agreement.error.start_date_in_the_past');
  else if (
    error.message === agreementErrorCode.HANDOVER_DATE_IN_PAST &&
    error.type === agreementErrorType.HANDOVER_DATE_IN_PAST
  )
    return t('agreement.error.handover_date_in_the_past');
  else if (
    error.message === agreementErrorCode.END_DATE_BEFORE_START_DATE &&
    error.type === agreementErrorType.END_DATE_BEFORE_START_DATE
  )
    return t('agreement.error.end_date_before_start_date');
  else if (
    error.message === agreementErrorCode.AGREEMENT_DURATION &&
    error.type === agreementErrorType.AGREEMENT_DURATION
  )
    return t('form.error.start_and_end_date_120_days_between_error');
  else if (
    error.message === agreementErrorCode.AGREEMENT_TYPE_IS_NOT_SPECIFIED &&
    error.type === agreementErrorType.AGREEMENT_TYPE_IS_NOT_SPECIFIED
  )
    return t('agreement.error.agreement_type_is_not_specified');
  // Data validation
  else if (
    error.type === agreementErrorType.addressFirstLine ||
    error.type === agreementErrorType.addressHouseNumber ||
    error.type === agreementErrorType.addressStreet ||
    error.type === agreementErrorType.addressCity ||
    error.type === agreementErrorType.addressCountry ||
    error.type === agreementErrorType.addressIndex
  )
    return t('agreement.error.address_problems');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.startDate
  )
    return t('agreement.error.start_date_is_required');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.endDate
  )
    return t('agreement.error.end_date_is_required');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.handoverDate
  )
    return t('agreement.error.handover_date_is_required');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.rentAmount
  )
    return t('agreement.error.rent_amount_must_be_valid');
  else if (
    error.message === agreementErrorCode.MUST_BE_A_POSITIVE_NUMBER &&
    error.type === agreementErrorType.rentAmount
  )
    return t('agreement.error.rent_amount_must_be_valid');
  else if (
    error.message === agreementErrorCode.MUST_BE_AT_LEAST_LIMIT_CHARACTERS_LONG &&
    error.type === agreementErrorType.bankAccountNumber
  )
    return t('agreement.error.bank_account_number_invalid');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.bankAccountNumber
  )
    return t('agreement.error.bank_account_number_is_required');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.bankAccountOwnerName
  )
    return t('agreement.error.bank_account_owner_is_required');
  else if (
    error.message === agreementErrorCode.NUMBER_LESS_THAN_ALLOWED &&
    error.type === agreementErrorType.paymentDay
  )
    return t('agreement.error.payment_day_must_be_valid');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.paymentDay
  )
    return t('agreement.error.payment_day_must_be_valid');
  else if (
    error.message === agreementErrorCode.CONTAINS_AN_INVALID_VALUE &&
    error.type === agreementErrorType.insuranceInvoiceTo
  )
    return t('agreement.error.insurance_invoice_to_invalid');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.insuranceInvoiceTo
  )
    return t('agreement.error.insurance_invoice_to_invalid');
  else if (
    error.message === agreementErrorCode.MUST_BE_A_POSITIVE_NUMBER &&
    error.type === agreementErrorType.objectArea
  )
    return t('form.validation.objectArea');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.objectArea
  )
    return t('form.validation.objectArea');
  else if (
    error.message === agreementErrorCode.MUST_BE_A_POSITIVE_NUMBER &&
    error.type === agreementErrorType.numberOfRooms
  )
    return t('form.validation.numberOfRooms');
  else if (
    error.message === agreementErrorCode.MUST_BE_AN_INTEGER &&
    error.type === agreementErrorType.numberOfRooms
  )
    return t('form.validation.numberOfRooms');
  else if (
    error.message === agreementErrorCode.REQUIRED_FIELD &&
    error.type === agreementErrorType.numberOfRooms
  )
    return t('form.validation.numberOfRooms');
  else if (
    error.message === agreementErrorCode.CONTAINS_AN_INVALID_VALUE &&
    error.type === agreementErrorType.additionalFees
  )
    return t('agreement.error.additional_fees_invalid');
  else if (
    error.message === agreementErrorCode.CONTAINS_AN_INVALID_VALUE &&
    error.type === agreementErrorType.servicesAndUtilities
  )
    return t('agreement.error.services_and_utilities_invalid');
  else if (
    error.message === agreementErrorCode.CONTAINS_AN_INVALID_VALUE &&
    error.type === agreementErrorType.administrativeFees
  )
    return t('agreement.error.administrative_fees_invalid');

  return t('agreement.error.please_contact_customer_support');
}
